import * as React from "react"
import { graphql } from "gatsby"
import { multipleFromGatsby as multipleFromGatsbyImpacts } from "../gatsby/transformers/impactTransformer"
import AboutLandingPage from "../social-supermarket/pages/about/AboutLandingPage"

const About = ({ data }): JSX.Element => {
  const impacts = multipleFromGatsbyImpacts(data.allWpImpact.nodes)

  return <AboutLandingPage impacts={impacts} />
}

export default About

export const query = graphql`
  {
    allWpImpact {
      nodes {
        slug
        icon {
          iconSvg
        }
      }
    }
  }
`
