import React, { FC } from "react"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { skyBlue, lightBlack } from "../../constants/colors"
import { desktop, smallMobile } from "../../constants/screenSizes"
import InnerBlock from "../../components/landing-page/InnerBlock"
import Part from "../../components/landing-page/Part"
import Title from "../../components/landing-page/Title"
import Subheading from "../../components/landing-page/Subheading"
import { Icon } from "semantic-ui-react"

const SectionHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 60px;

  @media (max-width: ${desktop}px) {
    margin: 40px 0;
  }
  @media (max-width: ${smallMobile}px) {
    margin: 25px 0 40px;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const LinkIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
`

const GoToLink = styled(GLink)<{ margin?: string; iconMargin?: string }>`
  margin: ${({ margin }) => margin || 0};
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.8px;
  color: ${lightBlack};
  cursor: pointer;
  &:hover,
  &:visited {
    color: ${lightBlack};
    text-decoration: none;
  }
  ${LinkIcon} {
    margin: ${({ iconMargin }) => iconMargin || 0};
    height: unset;
  }

  @media (max-width: ${smallMobile}px) {
    margin-top: 20px;
  }
`

interface SectionHeaderProps {
  subheading: string
  title: string
  link: string
  linkLabel: string
}

export const SectionHeader: FC<SectionHeaderProps> = ({
  subheading,
  title,
  link,
  linkLabel,
}): JSX.Element => {
  return (
    <InnerBlock>
      <Part width="100%">
        <Subheading isBordered borderColor={skyBlue}>
          {subheading}
        </Subheading>
      </Part>
      <SectionHeaderContainer>
        <Title>{title}</Title>
        <GoToLink iconMargin="0 0 0 10px" to={link}>
          {linkLabel}
          <LinkIcon name="arrow right" color={skyBlue} />
        </GoToLink>
      </SectionHeaderContainer>
    </InnerBlock>
  )
}

export default SectionHeader
