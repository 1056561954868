import React from "react"
import styled from "styled-components"
import { white } from "../../constants/colors"
import IconHandle from "../../../svg/icon-imagerslider-handle.inline.svg"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  height: 100%;
  cursor: ew-resize;
  pointer-events: none;
`

const Lines = styled.div`
  flex-grow: 1;
  height: 100%;
  width: 4px;
  background-color: ${white};
  pointer-events: auto;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
`

const Button = styled.div`
  width: 26px;
  height: 26px;
  pointer-events: auto;
  border-radius: 50%;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.35);
`

const CompareSliderHandle: React.FC = (): JSX.Element => (
  <Container>
    <Lines />
    <Button>
      <IconHandle />
    </Button>
    <Lines />
  </Container>
)

export default CompareSliderHandle
